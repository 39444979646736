.root {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: #377eb6;
  overflow: hidden;
}

.container {
  flex: 1;
  position: relative;
  width: 100%;
  background: radial-gradient(circle, rgba(55, 126, 182, 0.7960434173669468) 0%, rgba(113, 37, 213, 0.38147759103641454) 100%);
}

.container2 {
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: radial-gradient(#e5e5f7 0.75px, #377eb6 0.75px);
  background-size: 15px 15px;
}

.children {
  width: 100%;
  height: 100%;
}

.center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.end {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
}

.left {
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: end;
}

.leftCenter {
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: end;
}

.man {
  height: 80vh;
  opacity: 1;
  margin-left: 20rem;
}

.menina {
  height: 100vh;
  opacity: 0.5;
}

.circle {
  font-weight: bold;
  font-size: 1rem;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4963B6;
  background: #FCD103;
  border-radius: 50%;
}

.text {
  display: flex;
  flex-direction: column;
  padding: 10rem;
  gap: 1rem;
}

@media only screen and (max-width: 565px) {
  .text {
    padding: 2rem 2rem 10rem;
  }
  .man {
    height: 80vh;
    margin-left: 0 !important;
    opacity: 0.5;
  }
}