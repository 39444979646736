@import "src/animation/animation.module";

$animationSpeed: 40s;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #377eb6;
}

.container {
  flex: 1;
  position: relative;
  width: 100%;
  background: radial-gradient(circle, rgba(57, 186, 225, 1) 0%, rgba(55, 126, 182, 0.5663515406162465) 100%);
}

.children {
  width: 100%;
  height: 100%;
}

.woman {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
}

.img {
  height: 60vh;
}

.wifi2 {
  margin-left: 5em;
  margin-bottom: 30vh;
  height: 30vh;
}

.imageAnimation {
  height: 60vh;
  animation: fadeInUp 1s ease-in-out;
}

.riscos {
  height: 40vh;
  opacity: 0.1;
}

.right {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: right;
}

.left {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: left;
}

.topRight {
  height: 100%;
  display: flex;
  justify-content: right;
}

/*
flex
font-normal
overflow-hidden
w-[250px]
h-full
justify-center
items-center
text-4xl
break-words
*/

/*.text {
  color: white;
  display: flex;
  overflow: hidden;
  width: 250px;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-wrap: break-word;
}*/

.footer {
  z-index: 900;
  flex: 0 0 16vh;
  width: 100%;
  background: #ff7f1f;
  box-shadow: 0 0 56px 64px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  align-content: center;
}

.welcomeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100%;
  color: white;
  overflow: hidden;
}

.text:after {
  content: '|';
  margin-left: 5px;
  opacity: 1;
  animation: blinks .7s infinite;
}


@media only screen and (max-width: 600px) {
  .right {
    display: none;
  }
}

@media only screen and (max-width: 1070px) {
  .slideTrack {
    align-items: center;
    padding-left: 1rem !important;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 1040px) {
  .slideTrack {
    animation: scroll $animationSpeed linear infinite;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}


@media only screen and (max-width: 500px) {
  .right {
    display: flex;
  }
  .img {
    height: 40vh;
  }
  .woman {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .welcomeSection {
    align-items: end;
    justify-content: end;
    margin-bottom: 2rem;
  }
  .wifi2 {
    margin-left: -4em;
    margin-bottom: -23vh;
    height: 20vh;
  }
}

.slide {
  height: 100%;
  position: relative;
  width: 100%;
}

.slideTrack {
  align-items: center;
  padding-left: 9rem;
  display: flex;
  gap: 2rem;
}

.slide {
  color: white;
  height: 100%;
  width: 400px;
}

.icon {
  color: #9e490b;
  font-size: 3em;
}

// flex justify-center items-center gap-5
.slideTextSecondary {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

// w-auto text-2xl break-words text-left
.slideTextPrimary {
  width: 300px;
  word-break: break-word;
  text-align: left;
  color: white;
}