@import "src/animation/animation.module";

.root {
  overflow: hidden;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(90deg, rgba(242, 207, 48, 1) 0%, rgba(213, 125, 37, 1) 100%);
}

.container {
  flex: 1;
  display: flex;
  position: relative;
  width: 100%;
}

.children {
  width: 100%;
  height: 100%;
}

.center {
  overflow-x: scroll;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.bottomCenter {
  justify-content: end;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottomCenterText {
  padding: 1rem;
  justify-content: end;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.roteador {
  margin-bottom: 20px;
  height: 20%;
  z-index: 50;
}

p{
  color: white;
  font-weight: bold;
}

$animationSpeed: 40s;

@media only screen and (max-width: 1370px) {
  .slideTrack {
    //animation: scroll_Card $animationSpeed linear infinite;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .slideTrack {
    padding-left: 20rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
  .roteador {
    display: none;
  }
}

@media only screen and (max-width: 730px) {
  .slideTrack {
    padding-left: 25rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 635px) {
  .slideTrack {
    padding-left: 30rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 560px) {
  .slideTrack {
    padding-left: 35rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 480px) {
  .slideTrack {
    padding-left: 40rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .slideTrack {
    padding-left: 45rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 330px) {
  .slideTrack {
    padding-left: 50rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

.slider {
  overflow-x: auto;
  position: relative;
}

.slideTrack {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
