.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #377eb6;
}

.children {
  width: 100%;
  height: 100%;
}

a{
  color: #377eb6;
}

.container {
  flex: 1;
  position: relative;
  width: 100%;
  background: radial-gradient(circle, rgba(55, 126, 182, 0.7960434173669468) 0%, rgba(113, 37, 213, 0.38147759103641454) 100%);
}

.container2 {
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: radial-gradient(#e5e5f7 0.75px, #377eb6 0.75px);
  background-size: 15px 15px;
}

.todoList {
  width: 50%;
  background: white;
  border-radius: 16px;
  padding: 0.9rem;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #4963B6;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonAcordeon {
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: none;
}

.acordeon {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

@media only screen and (max-width: 300px) {
  .circle {
    width: 30px;
    height: 30px;
  }
}