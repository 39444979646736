.root {
  overflow: hidden !important;
  color: white;
  width: auto;
  height: auto;
  min-width: 180px !important;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: start;
  padding: 2rem;
  gap: 1rem;
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(190, 84, 16, 1) 0%, rgba(255, 113, 23, 1) 100%);
}

.textPrimary {
  line-height: 0.6;
  text-wrap: normal;
  width: auto;
  color: #FCD103;
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 1rem;
}

.textPrimarySymbol {
  color: white;
  margin-left: -0.2em;
}

.textSecondary {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: start;
}

.textSecondarySpan {
  font-weight: bold;
  font-size: 0.8rem;
  margin-left: 1rem;
}

.textBalance {
  font-weight: bold;
  font-size: 2rem;
}

.button {
  z-index: 999;
  outline: none;
  align-items: center;
  border: 2px solid white;
  border-radius: 24px;
  color: white;
  padding: 0.5rem;
  background: transparent;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}

.button:hover {
  background: white;
  color: #9e490b;
}