.header {
  z-index: 999;
  top: 0;
  position: fixed;
  width: 100%;
  height: 4rem;
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.1);
  gap: 1rem;
}

.header2 {
  z-index: 999;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  gap: 1rem;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.88);

  .buttonPlan{
    margin-left: 35px;
  }

}

.headerRight {
  display: flex;
  gap: 1rem;
  padding-right: 2rem;
}


.buttonPlan {
  outline: none;
  border: none;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background: #ff7f1f;
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .headerRight {
    display: none;
  }
  .buttonMobile {
    outline: none;
    border: none;
    background: none;
    color: white;
    font-size: 1rem;
    display: flex !important;
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 300px) {
  .buttonPlan{
    font-size: 0.6em;
  }
}

.buttonMobile {
  display: none;
}


