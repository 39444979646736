/** Base reset */
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif !important;
  background: #377eb6;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Track */

::-webkit-scrollbar-track {
  background: #377eb6;
}

/* Handler */

::-webkit-scrollbar-thumb {
  background: #d0d3d5;
  /*background: #f88938;*/
}

/* Handler on Hover */

::-webkit-scrollbar-track:hover {
  /*background: #555;*/
}